<template>
  <div class="pageview">
    <!-- banner切换 -->
    <van-swipe class="homebanner" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        ><img
          style="height: 17vh"
          :src="require('../../assets/partymember/wuxing01.png')"
      /></van-swipe-item>
      <!-- <van-swipe-item><img :src="require('../../assets/partymember/dangjian.jpg')" /></van-swipe-item>
      <van-swipe-item><img :src="require('../../assets/partymember/etuanjian.jpg')" /></van-swipe-item> -->
    </van-swipe>
    <!-- <div style="margin: 10px auto;border-radius: 5px;width: 95%;">
      <div style="border-radius: 8px;background: #ffff;"> -->
    <div
      style="
        border-radius: 8px;
        background: #ffff;
        padding: 3px;
        margin-top: 1.5vh;
      "
    >
      <van-grid
        class="wktype"
        :border="false"
        :column-num="5"
        style="margin-top: 0vh"
      >
        <!-- @click="gotoUrl('/fivestar/zhibu')" -->

        <!-- @click="gotoUrl('/fivestar/gongjian')"  -->
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/gongjian')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/gongjian.png')"
          ></van-image>
          <span class="wktext">生态宜居星</span>
        </van-grid-item>
        <!--  @click="gotoUrl('/fivestar/fazhi')" -->
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/fazhi')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/fazhi.png')"
          ></van-image>
          <span class="wktext">平安法治星</span>
        </van-grid-item>
        <!--  @click="gotoUrl('/fivestar/xingfu')"  -->
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/xingfu')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/xingfu.png')"
          ></van-image>
          <span class="wktext">文明幸福星</span>
        </van-grid-item>
        <!-- @click="gotoUrl('/fivestar/xingye')"  -->
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/xingye')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/yiye.png')"
          ></van-image>
          <span class="wktext">产业兴旺星</span>
        </van-grid-item>
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/zhibu')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/zhibu.png')"
          ></van-image>
          <span class="wktext">支部过硬星</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- </div>
    </div> -->
    <div style="margin: 10px auto; width: 100%">
      <img :src="require('../../assets/banner.png')" width="100%" />
      <!-- @click="gotoUrl('/fivestar/integral')" -->
    </div>

    <div class="contentmsg">
      <!-- <div class="contentA">
        <img src="../../assets/五星.png" alt="" width="15px" />
        <span>党建资讯</span>
      </div> -->
      <van-sticky>
        <van-tabs
          @click="onClickOnlyOne"
          color="#f63e3b"
          title-active-color="#f63e3b"
        >
          <van-tab name="全部" title="全部"> </van-tab>
          <van-tab
            v-for="item in CateList"
            :key="item.TKId"
            :name="item.TKId"
            :title="item.Name"
          >
          </van-tab>
        </van-tabs>
      </van-sticky>
      <div class="information">
        <div
          class="center"
          style="padding: 17px 16px"
          v-for="item in List"
          :key="item.index"
        >
          <div class="nian">
            {{ item.IssTime.split(" ")[0].split("-")[0] }}年
          </div>
          <div
            style="
              position: relative;
              top: -8px;
              width: 50px;
              height: 5px;
              background: linear-gradient(to right, #d44f43, #fff);
            "
          ></div>

          <div>
            <div style="display: flex; margin-bottom: 10px; margin-top: 14px">
              <span class="yue">{{
                item.IssTime.split(" ")[0].split("-")[1] +
                "/" +
                item.IssTime.split(" ")[0].split("-")[2]
              }}</span>
              <span class="yuan"></span>
              <div class="title">{{ item.Title }}</div>
            </div>

            <div style="display: flex" @click="gotoXQ(item)">
              <div class="line"></div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: baseline;
                "
              >
                <div class="content">
                  {{ item.Summary }}
                </div>
                <div class="img" ref="getHeight">
                  <img
                    v-if="item.Thematic != null && item.Thematic != ''"
                    :src="item.Thematic"
                    alt=""
                    style="min-height: 80px"
                  />
                  <img
                    v-else
                    src="http://alycommunity.anluoyun.cn/sx-jgz/2023/6/d230d06c-3c3.png"
                    alt=""
                    style="min-height: 80px"
                  />
                </div>
                <div class="recman">发布人：{{ item.IssMan }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 党建活动 -->
        <!-- <div
          class="center"
          v-for="item in List"
          :key="item.index"
          @click="gotoXQ(item)"
        >
          <div
            class="Content"
            style="border-bottom: 1px solid rgb(245 243 243)"
          >
            <div class="ContentB">
              <div class="centerTitle van-ellipsis" style="font-size: 16px">
                {{ item.Title }}
              </div>
              <span
                class="centertime van-multi-ellipsis--l3"
                style="margin: 5px 0px; font-size: 13px; height: 50px"
                >{{ item.Summary }}</span
              >
              <span class="centertime" style="color: #999999">{{
                item.IssTime
              }}</span>
            </div>
            <div
              style="display: inline-block; width: 27%"
              class="ContentA"
              ref="getHeight"
            >
              <img
                style="border-radius: 5px; min-height: 90px"
                v-if="item.Thematic"
                :src="item.Thematic"
                alt=""
              />
              <img
                v-else
                style="border-radius: 5px; min-height: 85px"
                src="https://lyxnyapi.dexian.ren//upload/images/2022/7/d0e8c771-cd6.png"
              />
            </div>
          </div>
        </div> -->
        <van-empty description="暂无通知" v-if="!List.length" />
        <!-- 公众号文章 -->
        <!-- <div class="center"
          v-for="item in List"
          :key="item.index">
          <router-link :to="'/fivestar/publicActicle/detail/' + item.ArticleId">
            <div class="Content"
              style="border-bottom: 1px solid rgb(245 243 243);">
              <div class="ContentB">
                <div class="centerTitle van-ellipsis"
                  style="font-size:16px">{{ item.Title }}</div>
                <span class="centertime van-multi-ellipsis--l2"
                  style="margin:5px 0px;font-size: 13px;">{{ item.Digest }}</span>
                <span class="centertime"
                  style="color:#999999;">{{ item.IssTime }}</span>
              </div>
              <div style="display: inline-block;width: 33%;"
                class="ContentA"
                ref="getHeight">
                <img v-if="item.ThumbUrl"
                  :src="item.ThumbUrl"
                  alt="" />
              </div>
            </div>
          </router-link>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { WeGetInfoKindInfoPage, WeGetPartyInfoPage } from "@/api/RealInfo";
import wx from "weixin-js-sdk";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  // computed: {
  //   ...mapGetters([
  //     'openId',
  //   ])
  // },
  components: {
    // Tabbar,
    // TipsMiniProject,
  },
  data() {
    return {
      List: [],
      CateList: [],
    };
  },
  created() {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setOpenId(this.$route.query["openid"]);
    }
    this.getPartyInfoList();
    this.getInfoKindInfoPage();
  },
  methods: {
    gotoXQ(row) {
      console.log(row);
      this.$router.push({
        name: "wuXingXq",
        query: { Id: row.RIId, add: 1 },
      });
    },
    // 获取资讯分类
    async getInfoKindInfoPage() {
      const res = await WeGetInfoKindInfoPage({ paKind: 2 });
      console.log(res, "res");
      this.CateList = res.data.data;
      console.log(this.CateList, "this.CateList");
    },
    onClickOnlyOne(name, title) {
      console.log(name);
      this.getPartyInfoList(name);
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    // 通知消息
    getPartyInfoList(val) {
      WeGetPartyInfoPage({ page: 1, limit: 10, paKind: 2, tkId: val }).then(
        (res) => {
          this.List = res.data.data;
          for (var i = 0; i < this.List.length; i++) {
            var item = this.List[i].IssTime;
            item = this.dataFormat(item);
            this.List[i].IssTime = item;
          }
        }
      );
    },
    // 获取公众号文章
    // getWxArticlePage () {
    //   WxGetWxArticlePage({ page: 1, limit: 15 }).then((res) => {
    //     this.List = res.data.data;
    //     for (var i = 0; i < this.List.length; i++) {
    //       var item = this.List[i].IssTime;
    //       // item = this.dataFormat(item);
    //       // this.List[i].IssTime = item;
    //       if (this.List[i].ThumbUrl == "") {
    //         this.List[i].ThumbUrl =
    //           "http://lccgzapi.zhsq.cloud//upload/images/2021/11/b6b3ab86-df3.png";
    //       }
    //     }
    //   });
    // },
    // 党务
    dangwu: function () {
      var url =
        "https://szhb.hebi.gov.cn/newsList?path=dangjianyaowen&title=%E5%85%9A%E5%BB%BA%E8%A6%81%E9%97%BB";
      window.localtion.href = url;
      // window.open('https://szhb.hebi.gov.cn/newsList?path=dangjianyaowen&title=%E5%85%9A%E5%BB%BA%E8%A6%81%E9%97%BB');
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
};
</script>

<style scoped>
.ContentB {
  display: inline-block;
  width: 50% !important;
  margin-right: 14%;
  color: #666666 !important;
  margin-left: 5% !important;
}
.djlink {
  margin-top: 1.5vh;
  border-radius: 10px;
  overflow: hidden;
  display: block;
  font-size: 0;
}
.contentA {
  width: 100%;
  overflow: hidden;
  line-height: 30px;
  padding: 2vh 1.5vh 0vh 1.5vh;
  box-sizing: border-box;
}
.contentA img {
  float: left;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-top: 7px;
}
.contentA span {
  float: left;
  font-size: 18px;
  color: #333333;
  font-weight: 700;
  line-height: 30px;
}
.contentA .newsmore {
  float: right;
  color: #777;
  font-size: 14px;
  line-height: 30px;
}
.contentA .blue {
  color: #477fff;
}
.pageview {
  background: #f6f6f6;
  min-height: 100%;
  padding: 1vh;
  box-sizing: border-box;
}
.homebanner img {
  width: 100%;
  height: 27vh;
  vertical-align: top;
}
.wktype {
  padding: 0;
  margin-top: 1.5vh;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 8px;
  font-size: 12px;
}
.contentmsg {
  background: #fff;
  margin-top: 1.5vh;
  border-radius: 8px;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 5px 10px 0px;
}
/deep/.wktype .van-grid-item__content {
  padding: 20px 3px;
}
.moreCount {
  color: #999999 !important;
  float: right !important;
  margin-right: 7px !important;
  font-size: 14px !important;
  font-style: initial !important;
}
.moreImg {
  float: right !important;
  width: 8px !important;
  height: 12px !important;
  margin-top: 9px !important;
}

.nian {
  position: relative;
  z-index: 1;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231815;
}

.yuan {
  width: 7px;
  height: 7px;
  background: rgb(252, 72, 57);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  margin: 7px 0;
}
.yue {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #231815;
  margin-right: 8px;
}

.line {
  width: 1px;
  height: 198px;
  background: #e8e8e8;
  margin-left: 52px;
  margin-right: 24px;
}

.title {
  margin-left: 21px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231815;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content {
  height: 63px;
  font-size: 14px;
  font-family: PingFang SC;
  color: #231815;
  font-weight: normal;
  margin-top: 6px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.img img {
  border-radius: 11px;
  height: 74px;
  width: 74px;
  margin-top: 12px;
}

.recman {
  margin-top: 10px;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}

/deep/ .van-tab {
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  /* color: #000000; */
  /* font-family: PingFangSC; */
}

/deep/ .van-tab--active {
  font-weight: bold;
  font-size: 15px;
  color: #187fc8;
}
</style>